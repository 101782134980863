import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/CloudUploadRounded";
import dayjs from "dayjs";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import UploadOfflineAttachment from "../../Dialogs/Upload/uploadOfflineAttachment";
import { red } from "@mui/material/colors";
import { CustomDropzone, CustomsmallDialog } from "../../Dialogs/Upload/style";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { downloadQuotationExcel } from "../../../config/apiUrl";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { openSnackbar } from "../../../redux/actions/snackbarAction";
import { ImportQuotation } from "../../../redux/actions/ImportQuotation";
import { getSymbol } from "../../AccountTemplate/CurrencySymbol";
import moment from "moment";

const QuotationDetailsSection = (props) => {
  
  const [fileUploadOpen, setFileUploadOpen] = useState(false);
  const [termsAndConditionsPopUp, setTermsAndConditionsPopUp] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date()); // State to hold the current date
  const [locData, setLocalData] = useState([]);
  const [newAttachmentValue, setNewAttachmentValue] = useState(null);
  const [offlineDateError, setOfflineDateError] = useState(
    props.offlineDateError
  );
  const [offlineFieldError, setOfflineFieldError] = useState(
    props.offlineFieldError
  );
  const [state, setState] = useState({
    currentTemplate: "",
    currentFieldId: "",
    // initialize other state properties here as needed
  });
  const userInfo = useSelector((state) => state.userInfoReducer.userInfo);
  const featurePermission = useSelector(
    (state) => state.permissionsReducer.featurePermission
  );
  const importedQuotation = useSelector(
    (state) => state.ImportQuotationReducer.quotation
  );
  const dispatch = useDispatch();
  const [paramHash, setParamHash] = useState({
    item_hader: [],
    rfq_detail_obj: {},
    vendor_obj: {},
    dropdown_req: {},
    vendor_field_array: [],
    rfq_field_array: [],
    item_obj: {},
    href_links_obj: {},
    can_change_val_obj: {},
    transaction_type:
      props.data.vendors[0].transaction_thread.length > 1
        ? "Requote"
        : "Quotation",
    inq_id: props.inquiryId,
    feild_info_ref: {},
    vendor_id: props.data?.vendors?.[0]?.id,
    item_sequence_array: [],
    new_item_sequence_array: [],
    href_links_obj: {},
    service_pr_permission: "N",
  });
  const [OpenQuotationExcel, setOpenQuotationExcel] = useState(false);
  const [validFiles, setValidFiles] = useState([]);
  const [errContent, setErrContent] = useState("");
  const [Loader, setLoader] = useState(false);
  const [DownloadLoader, setDownloadLoader] = useState(false);
  const [SuccessFlag, setSuccessFlag] = useState(false)
  // currentField.DefaultFormula.match(/f\d+/g) || [];
  // useEffect(() => {
  //   const commonObject = {};
  //   const locData = {};

  //   Object.keys(props.additionalInfo).forEach((key) => {
  //     if (
  //       props.commonData.some(
  //         (item) => item.Identifier == props.additionalInfo[key].Identifier
  //       )
  //     ) {
  //       commonObject[props.additionalInfo[key].FieldLabel] = props.additionalInfo[key].value;
  //     } else {
  //       locData[key] = props.additionalInfo[key];
  //     }
  //   });

  //   console.log("Common Object:", commonObject);
  //   console.log("Local Data:", locData);
  //   // props.additionalInfo.flat().map((commonFields) => {

  //   // })
  // }, [props.additionalInfo]);

  const [selectedItemObj, setselectedItemObj] = useState({});
  const [quotationDetails, setQuotationDetails] = useState({
    Inquiry: {
      //(type=Inquiry)
      offlineQuotationNo: "",
      rfqRefNo: "",
      currentDate: null,
      rfqDate: null,
      offlineQuotationDate: null,
      attachment: "", //Attachment for Offline Quotation
      rfqValidityDate: null,
      quotationValidity: null,
      reference: "",
    },
    Negotiation: {
      //Negotiation( type = Negotiation)
      offlineRequoteNo: "",
      quotationRefNo: "",
      negotiationRefNo: "",
      currentDate: null,
      negotiationDate: null,
      offlineRequoteDate: null,
      attachment: null,
      negotiationValidityDate: null,
      requoteValidity: null,
      reference: "",
    },
    Quotation: {
      //Requote 1 ( Type = Quotation )
      offlineRequoteNo: "",
      quotationRefNo: "",
      currentDate: null,
      quotationDate: null,
      offlineRequoteDate: null,
      attachment: null,
      quotationValidityDate: null,
      requoteValidity: null,
      reference: "",
    },
    Requote: {
      //Requote 2 (type = Requote)
      offlineRequoteNo: "",
      requoteRefNo: "",
      currentDate: null,
      requoteDate: null,
      previousOfflineQuoteRequoteDate: null,
      offlineRequoteDate: null,
      attachment: null,
      requoteValidityDate: null,
      requoteValidity: null,
      reference: "",
    },
  });
  const companyInfo = useSelector(
    (state) => state.companyInfoReducer.companyInfo
  );
  const accountlevelInfo = useSelector(
    (state) => state.permissionsReducer.accountlevelInfo
  );

  const condition = props?.lastTransactionTypeInThread;

  const { localData, foreignData, currencyOptions } = props;

  useEffect(() => {
    if (localData?.length > 0) {
      if (vendorType === "Local") {
        setLocalData(localData);
      } else {
        setLocalData(foreignData);
      }
    }
  }, [localData, foreignData]);

  const quotationDetailsLastData =
    props?.data?.vendors[0].transaction_thread.slice(-1)[0];

  const quotationDetailsSecondLastData =
    props?.data?.vendors[0].transaction_thread.slice(-2)[0];

  const vendorType = props?.vendorType;
  // useEffect hook to update the conditionData state when props change
  useEffect(() => {
    // Check the condition provided in props and extract the corresponding data
    let data;
    switch (condition) {
      case "Inquiry":
        data = quotationDetails.Inquiry;
        break;
      case "Negotiation":
        data = quotationDetails.Negotiation;
        break;
      case "Quotation":
        data = quotationDetails.Quotation;
        break;
      case "Requote":
        data = quotationDetails.Requote;
        break;
      default:
        data = {};
    }
    if (data.hasOwnProperty("attachment")) {
      data.attachment = newAttachmentValue;
    }
    // Set the conditionData state with the extracted data
    props?.dataFromQuotationDetails(data);
  }, [
    condition,
    quotationDetails,
    props?.dataFromQuotationDetails,
    newAttachmentValue,
  ]);

  const queryParams = new URLSearchParams(window.location.search);

  const getFieldsByCondition = (condition) => {
    switch (condition) {
      case "Inquiry":
        return [
          {
            id: "1",
            name: "offlineQuotationNo",
            label: "Offline Quotation No.",
            // isRequired: props.featurePermissionDetails === "Y" ? true : false,
            isRequired: true,
            isReadOnly: false,
            formId: "NewTranNo",
          },
          {
            id: "2",
            name: "rfqRefNo",
            label: "RFQ Ref. No.",
            isRequired: false,
            isReadOnly: true,
          },
          {
            id: "3",
            name: "currentDate",
            label: "Current Date",
            type: "date",
            isRequired: false,
            isReadOnly: true,
          },
          {
            id: "4",
            name: "rfqDate",
            label: "Date of RFQ",
            type: "date",
            isRequired: false,
            isReadOnly: true,
          },
          {
            id: "5",
            name: "offlineQuotationDate",
            label: "Offline Quotation Date",
            type: "date",
            isRequired:
              featurePermission?.offline_quote_requote_date_and_attachment
                ?.feature_value === "Y"
                ? true
                : false,
            isReadOnly: false,
            formId: "offline_quote_requote_date",
          },
          {
            id: "6",
            name: "attachment",
            label: "Attachment for Offline Quotation",
            type: "file",
            isRequired:
              featurePermission?.offline_quote_requote_date_and_attachment
                ?.feature_value === "Y"
                ? true
                : false,
            isReadOnly: false,
          },
          {
            id: "7",
            name: "rfqValidityDate",
            label: "RFQ Validity Date",
            type: "date",
            isRequired: false,
            isReadOnly: true,
          },
          {
            id: "8",
            name: "quotationValidity",
            label: "Quotation Validity",
            type: "date",
            isRequired: false,
            isReadOnly: false,
            formId: "datepicker",
          },
          {
            id: "9",
            name: "reference",
            label: "Reference",
            isRequired: true,
            isReadOnly: false,
            formId: "OfflineReference",
          },
        ];
      case "Negotiation":
        return [
          {
            id: "1",
            name: "offlineRequoteNo",
            label: "Offline Requote No.",
            isRequired: true,
            isReadOnly: false,
            formId: "NewTranNo",
          },
          {
            id: "2",
            name: "quotationRefNo",
            label:
              quotationDetailsSecondLastData.type !== "Requote"
                ? "Quotation Ref. No."
                : "Requote Ref. No.", // added by Sonali for issue 347
            isRequired: false,
            isReadOnly: true,
          },
          {
            id: "3",
            name: "negotiationRefNo",
            label: "Negotiation Ref. No.",
            isRequired: false,
            isReadOnly: true,
          },
          {
            id: "4",
            name: "currentDate",
            label: "Current Date",
            type: "date",
            isRequired: false,
            isReadOnly: true,
          },
          {
            id: "5",
            name: "negotiationDate",
            label: "Negotiation Date",
            type: "date",
            isRequired: false,
            isReadOnly: true,
          },
          {
            id: "6",
            name: "offlineRequoteDate",
            label: "Offline Requote Date",
            type: "date",
            isRequired:
              featurePermission?.offline_quote_requote_date_and_attachment
                ?.feature_value === "Y"
                ? true
                : false,

            isReadOnly: false,
            formId: "offline_quote_requote_date",
          },
          {
            id: "7",
            name: "attachment",
            label: "Attachment for Offline Requote",
            type: "file",
            isRequired:
              featurePermission?.offline_quote_requote_date_and_attachment
                ?.feature_value === "Y"
                ? true
                : false,
            isReadOnly: false,
          },
          {
            id: "8",
            name: "negotiationValidityDate",
            label: "Negotiation Validity Date",
            type: "date",
            isRequired: false,
            isReadOnly: true,
          },
          {
            id: "9",
            name: "requoteValidity",
            label: "Requote Validity",
            type: "date",
            isRequired: false,
            isReadOnly: false,
            formId: "datepicker",
          },
          {
            id: "10",
            name: "reference",
            label: "Reference",
            isRequired: true,
            isReadOnly: false,
            formId: "OfflineReference",
          },
        ];
      case "Quotation":
        return [
          {
            id: "1",
            name: "offlineRequoteNo",
            label: "Offline Requote No.",
            isRequired: true,
            isReadOnly: false,
            formId: "NewTranNo",
          },
          {
            id: "2",
            name: "quotationRefNo",
            label: "Quotation Ref. No.",
            isRequired: false,
            isReadOnly: true,
          },
          {
            id: "3",
            name: "currentDate",
            label: "Current Date",
            type: "date",
            isRequired: false,
            isReadOnly: true,
          },
          {
            id: "4",
            name: "quotationDate",
            label: "Quotation Date",
            type: "date",
            isRequired: false,
            isReadOnly: true,
          },
          {
            id: "6",
            name: "offlineRequoteDate",
            label: "Offline Requote Date",
            type: "date",
            isRequired:
              featurePermission?.offline_quote_requote_date_and_attachment
                ?.feature_value === "Y"
                ? true
                : false,
            isReadOnly: false,
            formId: "offline_quote_requote_date",
          },
          {
            id: "7",
            name: "attachment",
            label: "Attachment for Offline Requote",
            type: "file",
            isRequired:
              featurePermission?.offline_quote_requote_date_and_attachment
                ?.feature_value === "Y"
                ? true
                : false,
            isReadOnly: false,
          },
          {
            id: "8",
            name: "quotationValidityDate",
            label: "Quotation Validity Date",
            type: "date",
            isRequired: false,
            isReadOnly: true,
          },
          {
            id: "9",
            name: "requoteValidity",
            label: "Requote Validity",
            type: "date",
            isRequired: false,
            isReadOnly: false,
            formId: "datepicker",
          },
          {
            id: "10",
            name: "reference",
            label: "Reference",
            isRequired: true,
            isReadOnly: false,
            formId: "OfflineReference",
          },
        ];
      case "Requote":
        return [
          {
            id: "1",
            name: "offlineRequoteNo",
            label: "Offline Requote No.",
            isRequired: true,
            isReadOnly: false,
            formId: "NewTranNo",
          },
          {
            id: "2",
            name: "requoteRefNo",
            label: "Requote Ref. No.",
            isRequired: false,
            isReadOnly: true,
          },
          {
            id: "3",
            name: "currentDate",
            label: "Current Date",
            type: "date",
            isRequired: true,
            isReadOnly: true,
          },
          {
            id: "4",
            name: "requoteDate",
            label: "Requote Date",
            type: "date",
            isRequired: true,
            isReadOnly: true,
          },
          {
            id: "5",
            name: "previousOfflineQuoteRequoteDate",
            label: "Previous Offline Quote/Requote Date",
            type: "date",
            isRequired:
              featurePermission?.offline_quote_requote_date_and_attachment
                ?.feature_value === "Y"
                ? true
                : false,
            isReadOnly: true,
          },
          {
            id: "6",
            name: "offlineRequoteDate",
            label: "Offline Requote Date",
            type: "date",
            isRequired:
              featurePermission?.offline_quote_requote_date_and_attachment
                ?.feature_value === "Y"
                ? true
                : false,
            isReadOnly: false,
          },
          {
            id: "7",
            name: "attachment",
            label: "Attachment for Offline Requote",
            type: "file",
            isRequired:
              featurePermission?.offline_quote_requote_date_and_attachment
                ?.feature_value === "Y"
                ? true
                : false,
            isReadOnly: false,
          },
          {
            id: "8",
            name: "requoteValidityDate",
            label: "Requote Validity Date",
            type: "date",
            isRequired: true,
            isReadOnly: true,
          },
          {
            id: "9",
            name: "requoteValidity",
            label: "Requote Validity",
            type: "date",
            isRequired: true,
            isReadOnly: false,
            formId: "datepicker",
          },
          {
            id: "10",
            name: "reference",
            label: "Reference",
            isRequired: true,
            isReadOnly: false,
            formId: "OfflineReference",
          },
        ];
      default:
        return [];
    }
  };

  let conditionFields = getFieldsByCondition(condition);

  useEffect(() => {
    if (Object.keys(importedQuotation).length > 0) {
      conditionFields.forEach((fields) => {
        if (importedQuotation.result[fields.formId]) {
          setQuotationDetails((prevDetails) => ({
            ...prevDetails,
            [condition]: {
              ...prevDetails[condition],
              [fields.name]:
                fields.type == "date"
                  ? dayjs(
                      `${importedQuotation.result[fields.formId][0]}-${
                        Number.isInteger(
                          importedQuotation.result[fields.formId][1]
                        )
                          ? importedQuotation.result[fields.formId][1] + 1
                          : importedQuotation.result[fields.formId][1]
                      }-${importedQuotation.result[fields.formId][2]}`
                    )
                  : importedQuotation.result[fields.formId],
            },
          }));
        }
      });
    }
  }, [importedQuotation]);
  const removeUnstarredFields = (obj) => {
    // Create a new object to avoid modifying the original object directly
    let newObj = { ...obj };

    for (let key in obj) {
      if (key.endsWith("*")) {
        // Create the corresponding unstarred key
        let unstarredKey = key.slice(0, -2).trim();
        // Check if the unstarred key exists
        if (unstarredKey in newObj) {
          // Remove the unstarred key
          delete newObj[unstarredKey];
        }
      }
    }

    return newObj;
  };

  useEffect(() => {
    const commonObject = {};
    const vendorDetails = {};
    const mainObj = {};
    const can_change_val_obj = {};
    const dropdown_req = {};
    const feild_info_ref = {
      rfq_detail: {},
      item_detail: {},
      common_detail: {},
    };
    const href_links_obj = {};
    const item_seq = {};
    const selectedItem = {};

    if (Object.keys(props.itemizeData).length > 0) {
      // let data = props?.transactionItemSequence.filter((seq) => {
      //   if (Object.keys(props.itemizeData).includes(seq)) {
      //     return props.itemizeData[seq]?.selected === "Y";
      //   }
      // });
      
        let dataSeq = props?.transactionItemSequence.filter((seq) => {
          return Object.keys(props.itemizeData).includes(seq)
          
        });
      // Object.keys(props.itemizeData).forEach((key, index) => {
        dataSeq.forEach((key, index) => {
        const item_obj = {};
        const item_detail = {};
        if (
          props.itemizeData[key]?.selected === "Y" &&
          !props.disabledProps?.[key]
        ) {
          selectedItem[`Item${index + 1}`] = props.data.items[key]?.name;
       }
          Object.values(props.itemizeData[key]).map((fields) => {
            let iTextItemize = fields?.InstructionText
              ? " (" + fields?.InstructionText + ")"
              : "";
            if (index == 0) {
              if (
                fields?.Identifier !== "Product" &&
                fields?.Identifier !== "ProductForeign" &&
                fields?.Identifier !== "ProductCode" &&
                fields?.Identifier !== "MaterialSpecificationFile" &&
                fields?.ElementType !== "T" &&
                fields?.ElementType !== "F" &&
                fields?.ElementType !== "D"
              ) {
                if (typeof fields?.FieldLabel !== "undefined") {
                  can_change_val_obj[fields?.FieldLabel + iTextItemize] = 1;
                }
              }
              if (fields?.ElementType === "S") {
                const ItemizeOption = fields.options.map((item) =>
                  item.dropdown_element_name
                    ? companyInfo?.default_dropdown_type === "10" ||
                      fields.Identifier === "Destination" ||
                      fields.Identifier === "DestinationForeign" ||
                      fields.Identifier === "Currency"
                      ? `${item.dropdown_element_name}-${item.dropdown_element_code}`
                      : `${item.dropdown_element_code}-${item.dropdown_element_name}`
                    : item.code
                );
                ItemizeOption.unshift("Select");
                dropdown_req[fields?.FieldLabel + iTextItemize] = ItemizeOption;
              }
            }
            if (
              fields?.FieldLabel &&
              fields?.Identifier !== "Product" &&
              fields?.Identifier !== "ProductForeign"
            ) {
              if (fields?.ElementType === "DT") {
                item_detail[fields.FieldLabel + iTextItemize] = {
                  name: `datepicker${fields.HTMLAttributeName}`,
                };
                item_obj[fields.FieldLabel + iTextItemize] = `${
                  fields.value ? moment(fields.value).format("DD/MM/YYYY") : ""
                }##### IST`;
              } else {
                if (typeof fields?.FieldLabel !== "undefined") {
                  let unit;
                  let currency;
                  if (fields?.UnitsFlag == 1) {
                    unit = props.data.items[key].unit;
                  }
                  if (fields?.UnitsFlag == 3) {
                    unit = "Pack";
                  }
                  item_obj[
                    "Specifications"
                  ] = `Files : ${props.data.items[key].files.length}`;
                  if (
                    (fields?.CurrencyFormula === "BuyerLocalCurrency" &&
                      fields?.Identifier === "Rate") ||
                    fields?.CurrencyFormula === "SelectedCurrency"
                  ) {
                    currency =
                      props.lastTransactionTypeInThread === "Inquiry" &&
                      (props.vendorType === "International" ||
                        props.vendorType === "Local Importer")
                        ? props?.selectedCurrency
                        : props.quotedCurrency;
                    if (unit) unit = `${unit}`;
                  } else if (fields?.CurrencyFormula == "BuyerLocalCurrency") {
                    currency = "INR";
                  } else if (
                    fields?.CurrencyFormula != "" && fields?.CurrencyFormula.split("||") &&
                    fields?.CurrencyFormula.split("||")[0].split(".") &&
                    fields.CurrencyFlag != 0
                  ) {
                    currency = props?.selectedCurrency || props.quotedCurrency;
                    if (unit) unit = `${unit}`;
                  }
                  if (
                    fields.Identifier === "ProductQuantity" ||
                    fields.Identifier === "PER"
                  ) {
                    item_obj[
                      fields.FieldLabel + iTextItemize
                    ] = `${fields?.value}#####${unit}`;
                  } else if (
                    fields?.ElementType === "TB"
                  ) {
                   
                    item_obj[fields.FieldLabel + iTextItemize] = `${
                      fields.value
                    }#####${currency ? getSymbol(currency) : ""}  ${unit ? `per ${unit}` : ""}`;
                  } else if (
                    props.data.filter_data.multiple_solution &&
                    fields.Identifier == "ProductCode"
                  ) {
                    item_obj["Multiple Solution Name*"] = props.solutionNames[`${key}-0`];
                    item_obj[fields.FieldLabel + iTextItemize] = fields.value;
                    if (
                      !props.headerLabels.includes("Multiple Solution Name*")
                    ) {
                      props.headerLabels.push("Multiple Solution Name*");
                    }
                    item_detail["Multiple Solution Name*"] = {
                      name: `solutionname_${key}`,
                    };
                    can_change_val_obj["Multiple Solution Name*"] = 1;
                  } else {
                    item_obj[fields.FieldLabel + iTextItemize] =
                      fields.value == null || fields.value.length == 0
                        ? fields.ElementType === "S"
                          ? "Select"
                          : unit ? `${fields.value}#####${unit}` : fields.value
                        : unit ? `${fields.value}#####${unit}` : fields.value;
                  }
                  const is_required = fields?.FieldLabel?.includes("*");

                  item_detail[fields.FieldLabel + iTextItemize] = {
                    name: fields.HTMLAttributeName,
                  };

                  // if (is_required) {
                  //   item_detail[fields.FieldLabel]["req_field"] = 1;
                  // }
                }
              }
            }
          });
          item_seq[`Item${index + 1}`] = props.data.items[key]?.name;
          mainObj[
            `Item${index + 1} ##### ${props.data.items[key]?.name} ##### ${key}`
          ] = item_obj;
          feild_info_ref.item_detail[
            `Item${index + 1} ##### ${props.data.items[key]?.name} ##### ${key}`
          ] = item_detail;
        // }
      });
    }

    Object.keys(props.additionalInfo).forEach((key) => {
      if (props.additionalInfo[key]?.VisibilityFlag == "Y") {
        let templateCell = props.allItemsTemplate.find(
          (cell) => cell.FieldId === key
        );

        let iText = templateCell?.InstructionText
          ? " (" + templateCell?.InstructionText + ")"
          : "";
        if (
          props.commonData.some(
            (item) => item.Identifier == props.additionalInfo[key].Identifier
          )
        ) {
          if (props.additionalInfo[key].ElementType != "D") {
            if (props.additionalInfo[key].ElementType == "DT") {
              commonObject[props.additionalInfo[key].FieldLabel + iText] =
                moment(props.additionalInfo[key]?.value).format("DD/MM/YYYY");
            } else {
              if (props.additionalInfo[key].ElementType === "S") {
                let aditionalSvalue = props.additionalInfo[key]?.value
                if (props.additionalInfo[key]?.options !== "loading" && props.additionalInfo[key]?.options?.length > 0 && props.additionalInfo[key]?.options[0].hasOwnProperty['name']) {
                  
                  let dropdownName =  props.additionalInfo[key]?.options?.filter(option => {
                     return option.code === aditionalSvalue
                   })
                   if (dropdownName.length > 0) {
                    aditionalSvalue = dropdownName[0]?.name
                   }
                 }
                
                commonObject[props.additionalInfo[key].FieldLabel + iText] =
                  aditionalSvalue == null || aditionalSvalue == ""
                    ? "Select"
                    : aditionalSvalue;
              } else {

                commonObject[props.additionalInfo[key].FieldLabel + iText] =
                  props.additionalInfo[key]?.value == null ||
                  props.additionalInfo[key]?.value.length == 0
                    ? props.additionalInfo[key].ElementType === "S"
                      ? "Select"
                      : ""
                    : props.additionalInfo[key].value ||
                      props.additionalInfo[key].DefaultValue;
              }
            }
            if (
              props.additionalInfo[key].Identifier != "Destination" ||
              props.additionalInfo[key].Identifier != "DestinationForeign"
            ) {
              if (props.additionalInfo[key].ElementType !== "T") {
                if (props.additionalInfo[key].ElementType != "F") {
                  if (
                    (props.PermissionInquiry?.is_payment_term === "Y" &&
                      companyInfo.payment_term_identifier
                        .split(";")
                        .includes(props.additionalInfo[key].Identifier)) ||
                    (props.PermissionInquiry?.is_inco_term === "Y" &&
                      companyInfo.inco_term_identifier
                        .split(";")
                        .includes(props.additionalInfo[key].Identifier))
                  ) {
                  } else {
                    can_change_val_obj[
                      props.additionalInfo[key].FieldLabel + iText
                    ] = 1;
                  }
                }
                feild_info_ref.rfq_detail[
                  props.additionalInfo[key].FieldLabel + iText
                ] = {
                  name: props.additionalInfo[key].HTMLAttributeName,
                };
              }
              const is_required =
                props.additionalInfo[key]?.FieldLabel?.includes("*");

              if (is_required) {
                feild_info_ref.rfq_detail[
                  props.additionalInfo[key].FieldLabel + iText
                ]["req_field"] = 1;
              }
            }
          }
        } else {
          const totalValueElement = document.getElementById(
            "Total_val_basic_rate"
          );
          if (totalValueElement) {
            vendorDetails["Total Value"] = "";
          }
          if (
            props.additionalInfo[key].ElementType != "T" &&
            props.additionalInfo[key].ElementType != "L" &&
            props.additionalInfo[key].ElementType !== "F" &&
            props.additionalInfo[key].ElementType != "D"
          ) {
            if (
              (props.PermissionInquiry?.is_payment_term === "Y" &&
                companyInfo.payment_term_identifier
                  .split(";")
                  .includes(props.additionalInfo[key].Identifier)) ||
              (props.PermissionInquiry?.is_inco_term === "Y" &&
                companyInfo.inco_term_identifier
                  .split(";")
                  .includes(props.additionalInfo[key].Identifier))
            ) {
            } else {
              can_change_val_obj[
                props.additionalInfo[key].FieldLabel + iText
              ] = 1;
            }
          }
          let templateCell = props.allItemsTemplate.find(
            (cell) => cell.FieldId === key
          );
          if (
            props.additionalInfo[key].ElementType == "T" &&
            Array.isArray(props.additionalInfo[key]?.value) &&
            props.additionalInfo[key].Identifier == "AttachmentBuyer"
          ) {
            vendorDetails[props.additionalInfo[key].FieldLabel + iText] =
              props.additionalInfo[key]?.value[0]?.file_user_name;
            let fileIdModulo = props.additionalInfo[key]?.value[0]?.id % 10;
            href_links_obj[props.additionalInfo[key].FieldLabel + iText] = {
              [props.additionalInfo[key]?.value[0]
                ?.file_user_name]: `/cgi-bin/vendx/download.cgi?vendx_action=buyer_attachment&filepath=/opt/uploaded_Files/asp/${companyInfo?.display_name}/sourcing/${accountlevelInfo?.buyer_id}/${fileIdModulo}/${props.additionalInfo[key]?.value[0]?.id}&file_name=${props.additionalInfo[key]?.value[0]?.file_user_name}`,
            };
          } else {
            vendorDetails[
              props.additionalInfo[key].FieldLabel +
                (templateCell?.InstructionText
                  ? " (" + templateCell?.InstructionText + ")"
                  : "")
            ] =
              props.additionalInfo[key]?.value == null ||
              props.additionalInfo[key]?.value.length == 0
                ? props.additionalInfo[key].ElementType === "S"
                  ? "Select"
                  : props.additionalInfo[key].value
                : props.additionalInfo[key].value;
          }
          const is_required =
            props?.additionalInfo[key]?.FieldLabel?.includes("*");
          feild_info_ref.common_detail[
            props.additionalInfo[key].FieldLabel + iText
          ] = {
            name: props.additionalInfo[key].HTMLAttributeName,
          };
          
          if (is_required && props.additionalInfo[key].ElementType !== 'F') {
            feild_info_ref.common_detail[
              props.additionalInfo[key].FieldLabel + iText
            ]["req_field"] = 1;
          }
        }
        if (
          props.additionalInfo[key].ElementType === "S" &&
          props.additionalInfo[key].Identifier != "Currency" &&
          props.additionalInfo[key]?.options != "loading"
        ) {
          const CommonOption = props.additionalInfo[key]?.options.map((item) =>
          item.dropdown_element_name
          ? companyInfo?.default_dropdown_type === "10" ||
          props.additionalInfo[key].Identifier === "Destination" ||
          props.additionalInfo[key].Identifier === "DestinationForeign" ||
          props.additionalInfo[key].Identifier === "Currency"
          ? `${item.dropdown_element_name}-${item.dropdown_element_code}`
          : `${item.dropdown_element_code}-${item.dropdown_element_name}`
          : item.name
          );
          CommonOption.unshift("Select");
          if (
            (props.PermissionInquiry?.is_payment_term === "Y" &&
              companyInfo.payment_term_identifier
                .split(";")
                .includes(props.additionalInfo[key].Identifier)) ||
            (props.PermissionInquiry?.is_inco_term === "Y" &&
              companyInfo.inco_term_identifier
                .split(";")
                .includes(props.additionalInfo[key].Identifier))
          ) {
          } else {
            dropdown_req[props.additionalInfo[key].FieldLabel + iText] =
              CommonOption;
          }
        }
      }
    });
    if (vendorType !== "Local") {
      vendorDetails["Shipment Mark"] = props?.CurrentTransaction?.shipment_mark
        ? props?.CurrentTransaction?.shipment_mark
        : "N/A";
    }

    vendorDetails["Accept Terms and conditions"] = "";
    commonObject[
      featurePermission?.industry_type_mandatory?.feature_value === "Y"
        ? "Industry Type *"
        : "Industry Type"
    ] =
      props?.industryType +
      "#####" +
      (props?.msmeNumber ? props?.msmeNumber : "");
    feild_info_ref.rfq_detail[
      featurePermission?.industry_type_mandatory?.feature_value === "Y"
        ? "Industry Type *"
        : "Industry Type"
    ] = {
      name: "industry_type",
      ex_textbox: {
        name: "industry_type_value",
      },
    };
    if (props?.industryType == "MSME") {
      feild_info_ref.rfq_detail[
        featurePermission?.industry_type_mandatory?.feature_value === "Y"
          ? "Industry Type *"
          : "Industry Type"
      ]["ex_textbox"] = {
        name: "industry_type_value",
      };
    }
    can_change_val_obj[
      featurePermission?.industry_type_mandatory?.feature_value === "Y"
        ? "Industry Type *"
        : "Industry Type"
    ] = 1;
    const typeArray = industryOptions.map((item) => item.type);

    typeArray.unshift("Select");
    dropdown_req[
      featurePermission?.industry_type_mandatory?.feature_value === "Y"
        ? "Industry Type *"
        : "Industry Type"
    ] = typeArray;
    const hasCurrency = locData.find((item) => item.Identifier === "Currency");
    if (
      typeof hasCurrency != "undefined" &&
      (vendorType === "International" || vendorType === "Local Importer") &&
      Object.keys(hasCurrency).length > 0 &&
      hasCurrency?.VisibilityFlag === "Y"
    ) {
      const currencyArray = currencyOptions.map(
        (currency) => currency.Currency
      );

      currencyArray.sort();
      currencyArray.unshift("Select");
      let iTextCurrency = hasCurrency?.InstructionText
      ? " (" + hasCurrency?.InstructionText + ")"
      : "";
      dropdown_req[hasCurrency?.FieldLabel + iTextCurrency] = currencyArray;
    }

    let qotatinFields = {};

    // added by sonali for 254 issue :: START
    let onlineData = [];

    conditionFields.forEach((fields) => {
      const status = queryParams.get("supplier") ? "Online" : "Offline";

      if (status === "Online") {
        if (
          !["offlineQuotationDate", "attachment", "reference"].includes(
            fields.name
          )
        ) {
          // Remove "Offline" tag from the label if it exists
          if (fields.label.includes("Offline")) {
            fields.label = fields.label.replace("Offline", "").trim();
            fields.name = fields.name.replace("offline", "").trim();
          }

          onlineData.push(fields);
        }
      }
    });

    if (onlineData.length > 0) {
      conditionFields = onlineData;
    }

    // added by sonali for 254 issue :: END

    conditionFields.forEach((fields) => {
      if (!fields.isReadOnly && fields.type != "file") {
        can_change_val_obj[fields.label + (fields.isRequired ? " *" : "")] = 1;
        feild_info_ref.rfq_detail[
          fields.label + (fields.isRequired ? " *" : "")
        ] = {
          name: fields.formId,
        };
        if (fields.isRequired) {
          feild_info_ref.rfq_detail[
            fields.label + (fields.isRequired ? " *" : "")
          ]["req_field"] = 1;
        }
      }
      if (fields.isRequired && fields.type != "date") {
        qotatinFields[fields.label + (fields.isRequired ? " *" : "")] =
          quotationDetails[condition][fields.name];
      } else {
        qotatinFields[fields.label] = quotationDetails[condition][fields.name];
      }
      if (fields.type == "date") {
        let Label;
        if (
          fields.formId === "offline_quote_requote_date" &&
          featurePermission
        ) {
          Label =
            fields.label +
            (featurePermission?.offline_quote_requote_date_and_attachment
              ?.feature_value === "Y"
              ? " *"
              : "");
        } else {
          Label = fields.label;
        }

        qotatinFields[Label] = quotationDetails[condition][fields.name]
          ? // dayjs(quotationDetails[condition][fields.name], "YYYY-MM-DD HH:mm:ss").format(
            //   `DD/MM/YYYY HH:mm:ss [${quotationDetails[condition][fields.name]
            //     .split(" ")
            //     .pop()}]`
            // )
            !fields.isReadOnly
            ? `${dayjs(quotationDetails[condition][fields.name]).format(
                "DD/MM/YYYY"
              )}#####IST`
            : fields.name === "rfqValidityDate"
            ? `${dayjs(quotationDetails[condition][fields.name]).format(
                "DD/MM/YYYY HH:mm:ss [IST]"
              )}`
            : `${dayjs(quotationDetails[condition][fields.name]).format(
                "DD/MM/YYYY [IST]"
              )}`
          : "";
      }
    });
    qotatinFields["Category Name"] = props.data.categories[0].name;
    qotatinFields["Category Code"] = props.data.categories[0].code;
    qotatinFields = removeUnstarredFields(qotatinFields);
    const rfq_detail_obj = { ...qotatinFields, ...commonObject };
    if (props.data.categories[0].files) {
      rfq_detail_obj[
        "Specification"
      ] = `Files: ${props.data.categories[0].files.length}`;
    }
    // let slectedItem = item_seq
    setselectedItemObj(selectedItem);

    const totalCostCell = props.allItemsTemplate.find(
      (cell) => cell.DefaultValue?.Button === "Y"
    );
    const productFieldlabel = props.allItemsTemplate.find(
      (cell) =>
        cell?.Identifier === "Product" || cell?.Identifier === "ProductForeign"
    );
    if (Object.keys(mainObj).length > 0) {
      let headers = Object.keys(Object.values(mainObj)[0]);
      headers.unshift(productFieldlabel?.FieldLabel);
      headers = headers.filter((item) => item != totalCostCell?.FieldLabel);
      setParamHash({
        ...paramHash,
        rfq_detail_obj: rfq_detail_obj,
        vendor_obj: vendorDetails,
        item_hader: headers,
        // item_hader: props?.headerLabels,
        // item_hader : Object.keys(Object.values(mainObj)[0]),
        vendor_field_array: Object.keys(vendorDetails),
        rfq_field_array: Object.keys(rfq_detail_obj),
        item_obj: mainObj,
        dropdown_req,
        item_sequence_array: Object.keys(mainObj),
        new_item_sequence_array: Object.keys(mainObj),
        feild_info_ref,
        can_change_val_obj,
        href_links_obj,
      });
    }
  }, [
    props.additionalInfo,
    props.commonData,
    props.itemizeData,
    featurePermission,
    props.data,
    props.PermissionInquiry,
    quotationDetails,
    props.disabledProps,
    // props?.headerLabels,
    props?.selectedCurrency,
    props.solutionNames
  ]);
  const files = validFiles.map((file) => (
    <ListItem
      key={file.path}
      sx={{
        background: "var(--grey-100)",
        borderRadius: "4px",
        marginBottom: "10px",
        padding: "2px 10px",
        color: "text.primary",
      }}
    >
      <ListItemText
        primary={file.path}
        secondary={
          file.size > 1048575
            ? Math.round((file.size * 100.0) / 1048576) / 100 + " MB"
            : Math.round((file.size * 100.0) / 1024) / 100 + " KB"
        }
        sx={{ ml: 1 }}
        primaryTypographyProps={{
          // whiteSpace: "nowrap",
          // textOverflow: "ellipsis",
          overflow: "hidden",
          width: "calc(100% - 36px)",
          fontSize: "14px",
        }}
      />
    </ListItem>
  ));
  // const handleUplaodedFileDelete = (index) => {
  //   let temp = { ...props[props.currentTemplate] };
  //   temp[props.currentFieldId]["value"].splice(index, 1);
  //   props.setState({ [props.currentTemplate]: temp });
  // };

  // Function to set attachment values
  // const setAttachmentsValue = (value) => {
  //   console.log("setAttachmentsValue", value);
  //   setNewAttachmentValue(value); // Update the state with the new attachment values
  // };

  // Function to handle file deletion
  const handleUploadedFileDelete = (index) => {
    // Assuming 'newAttachmentValue' holds your current list of attachments
    let currentAttachments = [...newAttachmentValue]; // Create a copy of the current attachments array

    // Remove the attachment at the specified index
    currentAttachments.splice(index, 1);

    // Update the state with the new attachments array
    setAttachmentsValue(currentAttachments);
  };

  const handleFileUploadOpen = () => {
    setFileUploadOpen(true);
  };

  const handleTermsAndConditionsOpen = () => {
    termsAndConditionsPopUp(true);
  };

  const setAttachmentsValue = (value) => {
    setNewAttachmentValue(value);
  };

  const handleFieldChange = (condition, fieldName) => (event) => {
    // Clear error messages when TextField value changes
    if (
      fieldName === "offlineQuotationNo" ||
      fieldName === "offlineRequoteNo"
    ) {
      setOfflineFieldError("");
    } else if (
      fieldName === "offlineQuotationDate" ||
      fieldName === "offlineRequoteDate"
    ) {
      setOfflineDateError("");
    }

    // Update quotationDetails state
    setQuotationDetails({
      ...quotationDetails,
      [condition]: {
        ...quotationDetails[condition],
        [fieldName]: event.target.value,
      },
    });
  };

  const handleDateChange = (condition, fieldName) => (newVal) => {
    if (!newVal) {
      setQuotationDetails({
        ...quotationDetails,
        [condition]: {
          ...quotationDetails[condition],
          [fieldName]: null,
        },
      });
    } else {
      setQuotationDetails({
        ...quotationDetails,
        [condition]: {
          ...quotationDetails[condition],
          [fieldName]: newVal,
        },
      });
    }
  };

  const renderField = (condition, field) => {
    const status = queryParams.get("supplier") ? "Online" : "Offline";
    const label =
      status === "Online" && field.label.includes("Offline")
        ? field.label.replace("Offline ", "")
        : field.label;
    if (
      (status === "Online" &&
        ["offlineQuotationDate", "attachment", "reference"].includes(
          field.name
        )) ||
      // Exclude fields based on any other condition or criteria here
      false
    ) {
      return null; // Don't render the field
    }
    const value = quotationDetails[condition][field.name];
    const { isReadOnly, isRequired } = field;
    if (field.type === "date") {
      if (field.name == "previousOfflineQuoteRequoteDate" && value == "") {
        return null;
      }
      return (
        <FormControl sx={{ width: "100%" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              key={field.id}
              sx={{ width: "100%" }}
              label={label}
              value={value ? new Date(value) : null}
              disableFuture={
                field.name === "offlineQuotationDate" ||
                field.name === "offlineRequoteDate"
              }
              disablePast={
                field.name === "quotationValidity" ||
                field.name === "requoteValidity"
              }
              onChange={handleDateChange(condition, field.name)}
              format="dd/MM/yyyy hh:mm a"
              error={
                (field.name === "offlineQuotationDate" ||
                  field.name === "offlineRequoteDate") &&
                Boolean(props.offlineDateError)
                // &&!Boolean(value)
              }
              slotProps={{
                textField: {
                  // helperText: MaxRfqValidity != '' && `Maximum RFQ Validity ${MaxRfqValidity} IST`,
                  required: isRequired,
                  error:
                    (field.name === "offlineQuotationDate" ||
                      field.name === "offlineRequoteDate") &&
                    Boolean(props.offlineDateError) &&
                    !Boolean(value),
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={value}
                  error={
                    (field.name === "offlineQuotationDate" ||
                      field.name === "offlineRequoteDate") &&
                    Boolean(props.offlineDateError) &&
                    !Boolean(value)
                  }
                />
              )}
              InputProps={{
                readOnly: isReadOnly,
              }}
              readOnly={isReadOnly}
            />
            {Boolean(props.offlineDateError) && !Boolean(value) && (
              <FormHelperText sx={{ color: red[500] }}>
                {(field.name === "offlineQuotationDate" ||
                  field.name === "offlineRequoteDate") &&
                  props.offlineDateError}
              </FormHelperText>
            )}
          </LocalizationProvider>
        </FormControl>
      );
    } else if (field.name === "attachment" && field.type === "file") {
      let attachmentCount = newAttachmentValue?.length
        ? newAttachmentValue?.length
        : "0";
      return (
        <FormControl
          sx={{ width: "100%" }}
          error={attachmentCount == 0 && Boolean(props.offlineAttachmentError)}
        >
          <Box
            sx={{
              border: "solid 1px",
              //              borderColor: (theme) => theme.palette.bluegrey[500],
              borderColor: (theme) =>
                attachmentCount == 0 && Boolean(props.offlineAttachmentError)
                  ? theme.palette.error[500]
                  : theme.palette.bluegrey[500],
              borderRadius: "4px",
              minWidth: "100%",
              height: "56px",
              display: "flex",
              alignItems: "center",
              padding: "0 10px",
              position: "relative",
            }}
          >
            <InputLabel
              sx={{
                color: "text.secondary",
                backgroundColor: (theme) => theme.palette.grey[0],
                position: "absolute",
                left: 0,
                top: 0,
                paddingLeft: "5px",
                paddingRight: "5px",
                transformOrigin: "top left",
                webkitTransform: " translate(14px, -9px) scale(0.75)",
                transform: "translate(14px, -9px) scale(0.75)",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "calc(133% - 24px)",
                zIndex: 1,
              }}
            >
              {label + (field.isRequired ? " *" : "")}
            </InputLabel>
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ fontSize: "12px" }}
              onClick={() => {
                field.name === "attachment"
                  ? handleFileUploadOpen()
                  : handleTermsAndConditionsOpen();
              }}
            >
              <UploadIcon sx={{ marginRight: "10px" }} />
              {field.name === "attachment"
                ? "Attachment"
                : "View Terms & Conditions"}
            </Button>
            <FormHelperText sx={{ ml: "auto" }}>
              Number Of Files {attachmentCount}
            </FormHelperText>
          </Box>
          <Typography variant="caption">
            {/* {this.state.localTemplate[cell.HTMLAttributeName]} */}
          </Typography>
          {field.name === "attachment" &&
            attachmentCount == 0 &&
            Boolean(props.offlineAttachmentError) && (
              <FormHelperText sx={{ color: red[500] }}>
                {props.offlineAttachmentError}
              </FormHelperText>
            )}
          {/* {error && <FormHelperText>{ErrorMessage}</FormHelperText>} */}
        </FormControl>
      );
    } else {
      return (
        <>
          <TextField
            label={label}
            fullWidth
            value={value}
            error={
              (field.name === "offlineQuotationNo" ||
                field.name === "offlineRequoteNo" ||
                field.name === "reference") &&
              Boolean(props.offlineFieldError || props.offlineReferenceError) &&
              !Boolean(value)
            }
            onChange={handleFieldChange(condition, field.name)}
            InputProps={{
              readOnly: isReadOnly,
            }}
            required={isRequired}
          />
          {Boolean(props.offlineFieldError || props.offlineReferenceError) && (
            <FormHelperText sx={{ color: red[500] }}>
              {((field.name === "offlineQuotationNo" &&
                props.offlineFieldError) ||
                (field.name === "offlineRequoteNo" &&
                  props.offlineFieldError) ||
                (field.name === "reference" && props.offlineReferenceError)) &&
                !Boolean(value) && (
                  <FormHelperText sx={{ color: red[500] }}>
                    {field.name === "offlineQuotationNo" &&
                      props.offlineFieldError}
                    {field.name === "offlineRequoteNo" &&
                      props.offlineFieldError}
                    {field.name === "reference" && props.offlineReferenceError}
                  </FormHelperText>
                )}
            </FormHelperText>
          )}
        </>
      );
    }
  };

  const handleCloseExcel = () => {
    setOpenQuotationExcel(false);
    setErrContent("");
    setValidFiles([]);
    setSuccessFlag(false);
  };
  const importQuotation = () => {
    setLoader(true);

    let formData = new FormData();
    let tranType =
      props.data.vendors[0].transaction_thread.length > 1
        ? "Requote"
        : "Quotation";

    formData.append("param_hash", JSON.stringify(paramHash));
    formData.append("action", "upload_transaction_excel");
    formData.append("buyerid", userInfo?.division_id);
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    validFiles.forEach((file) => {
      // formData.append("files[]", file);
      formData.append("upload_file", file);
    });
    axios
      .post(downloadQuotationExcel, formData) // Set responseType to 'arraybuffer' to receive binary data
      .then((res) => {
        setLoader(false);
        if (res.data.error == 1) {
          // added to solve issue 281
          // dispatch(ImportQuotation(res.data));
          setValidFiles([]);

          // setting error content
          setErrContent(res.data.error_msg);
        } else {
          setSuccessFlag(true);
          dispatch(ImportQuotation(res.data));
          // setOpenQuotationExcel(false);
          setValidFiles([]);
        }
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  useEffect(() => {
    if (props.data) {
      const { expiry_at, validity_date, action_date, ref_no, rfq_date, no } =
        props.data;
      const propsData = props?.data?.vendors[0]?.transaction_thread[0];
      const formattedDate = dayjs(expiry_at, "YYYY-MM-DD HH:mm:ss").toDate();
      const rfqDate = dayjs(rfq_date, "YYYY-MM-DD HH:mm:ss").toDate();
      const quotationDate = propsData?.action_date;
      const validityDate = dayjs(
        propsData?.validity_date,
        "YYYY-MM-DD HH:mm:ss"
      ).toDate();
      setCurrentDate(new Date());
      setQuotationDetails((prevQuotationDetails) => ({
        ...prevQuotationDetails,
        Inquiry: {
          ...prevQuotationDetails.Inquiry,
          offlineQuotationNo: "",
          rfqRefNo: ref_no,
          currentDate: currentDate,
          rfqDate: rfqDate,
          // offlineQuotationDate: formattedDate,
          rfqValidityDate: formattedDate,
          quotationValidity: formattedDate,
        },
        Negotiation: {
          ...prevQuotationDetails.Negotiation,
          // offlineRequoteNo: quotationDetailsLastData.ref_no,
          quotationRefNo: quotationDetailsSecondLastData.no,
          negotiationRefNo: quotationDetailsLastData.no,
          currentDate: new Date(),
          negotiationDate: dayjs(
            quotationDetailsLastData.action_date,
            "YYYY-MM-DD HH:mm:ss"
          ).toDate(),
          // offlineRequoteDate: null,
          // attachment: "",
          attachment: newAttachmentValue,
          negotiationValidityDate: dayjs(
            quotationDetailsLastData.validity_date,
            "YYYY-MM-DD HH:mm:ss"
          ).toDate(),
          requoteValidity: dayjs(
            quotationDetailsLastData.validity_date,
            "YYYY-MM-DD HH:mm:ss"
          ).toDate(),
          // reference: "",
        },
        Quotation: {
          ...prevQuotationDetails.Quotation,
          // offlineRequoteNo: "",
          quotationRefNo: quotationDetailsLastData.no,
          currentDate: new Date(),
          quotationDate: dayjs(
            quotationDetailsLastData.action_date,
            "YYYY-MM-DD HH:mm:ss"
          ).toDate(),
          // offlineRequoteDate: null,
          attachment: newAttachmentValue,
          quotationValidityDate: dayjs(
            quotationDetailsLastData.validity_date,
            "YYYY-MM-DD HH:mm:ss"
          ).toDate(),
          requoteValidity: dayjs(
            quotationDetailsLastData.validity_date,
            "YYYY-MM-DD HH:mm:ss"
          ).toDate(),
          reference: "",
        },
        Requote: {
          ...prevQuotationDetails.Requote,
          currentDate: new Date(),
          requoteRefNo: quotationDetailsLastData.no,
          requoteDate: dayjs(
            quotationDetailsLastData.action_date,
            "YYYY-MM-DD HH:mm:ss"
          ).toDate(),
          previousOfflineQuoteRequoteDate:
            quotationDetailsLastData.quote_received_date
              ? dayjs(
                  quotationDetailsLastData.quote_received_date,
                  "YYYY-MM-DD HH:mm:ss"
                ).toDate()
              : "",
          requoteValidityDate: dayjs(
            quotationDetailsLastData.validity_date,
            "YYYY-MM-DD HH:mm:ss"
          ).toDate(),
          requoteValidity: dayjs(
            quotationDetailsLastData.validity_date,
            "YYYY-MM-DD HH:mm:ss"
          ).toDate(),
          offlineRequoteDate: null,
          attachment: newAttachmentValue,
        },
      }));
      // const { rfq_detail_obj, can_change_val_obj } = extractValues();
      // console.log("rfq_detail_obj:", rfq_detail_obj);
      // console.log("can_change_val_obj:", can_change_val_obj);
    }
  }, [props.data]);

  const industryOptions = [
    {
      type: "MSME",
    },
    {
      type: "OTHER",
    },
  ];

  const downloadSampleFile = (excel_type) => {
    const allSelectedN = Object.values(props.itemizeData).every(
      (item) => item.selected === "N"
    );
    if (allSelectedN) {
      dispatch(openSnackbar("error", "Select atlest one item"));
      return false;
    }
    setDownloadLoader({ [excel_type]: true });
    let formData = new FormData();
    let tranType =
      props.data.vendors[0].transaction_thread.length > 1
        ? "Requote"
        : "Quotation";
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    const today = `${day < 10 ? "0" : ""}${day}${
      month < 10 ? "0" : ""
    }${month}${year}-${hour < 10 ? "0" : ""}${hour}${
      minute < 10 ? "0" : ""
    }${minute}${second < 10 ? "0" : ""}${second}`;

    let $file_name = `${tranType}_form_for_${
      props.data.ref_no.split("/")[props.data.ref_no.split("/").length - 1]
    }_${userInfo?.division_id}_${today}`;

    formData.append("param_hash", JSON.stringify(paramHash));
    formData.append("excel_type", excel_type);
    formData.append("action", "download_transaction_excel");
    formData.append("buyerid", userInfo?.division_id);
    formData.append("TLGUID", props.CurrentTransaction.guid);
    formData.append("selected_item_obj", JSON.stringify(selectedItemObj));
    axios.defaults.headers.common["Content-Type"] =
      "application/x-www-form-urlencoded";
    axios
      .post(downloadQuotationExcel, formData, { responseType: "arraybuffer" })
      .then((res) => {
        setDownloadLoader({ [excel_type]: false });

        try {
          const blob = new Blob([res.data], {
            type: "application/octet-stream",
          });
          if (window.URL && typeof window.URL.createObjectURL === "function") {
            // Use createObjectURL if available
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `${$file_name}.xlsx`; // Set the filename here
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          } else {
            // Fallback to FileReader if createObjectURL is not available
            const reader = new FileReader();
            reader.onload = function (e) {
              const url = reader.result;
              const link = document.createElement("a");
              link.href = url;
              link.download = `${$file_name}.xlsx`; // Set the filename here
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            };
            reader.readAsDataURL(blob);
          }
        } catch (error) {
          console.error("Error handling file download:", error);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setDownloadLoader({ [excel_type]: false });

        // Handle specific error scenarios here
      });
  };
  const handleFilesChange = (newFile) => {
    let validFilesList = [];
    newFile.forEach((file) => {
      if (!file.name.match(/\.(xls|xlsx)$/i)) {
        dispatch(
          openSnackbar(
            "error",
            "Invalid file type Please upload only XLS or XLSX files."
          )
        );
      } else if (file.size > 26214400) {
        dispatch(openSnackbar("error", "File size cannot exceed 25 MB."));
      } else {
        // setFileUploadErr(false);
        validFilesList.push(file);
      }
    });

    return validFilesList;
  };
  return (
    <>
      <Card
        variant="outlined"
        // className="template-card local_recepients_card"
        id="QuotationDetails"
      >
        <CardHeader
          title={props.title}
          titleTypographyProps={{
            variant: "subtitle2",
            component: "h5",
            color: "grey.900",
            textTransform: "uppercase",
          }}
          sx={{
            minHeight: "50px",
            "&.MuiCardHeader-root": {
              flexDirection: { xs: "column", sm: "row" },
              alignItems: { xs: "start", sm: "center" },
            },
            "& .MuiCardHeader-action": {
              marginRight: 0,
              marginTop: 0,
            },
          }}
          
          action={
            <>
            
            {( props.slabFlag !== true ) && // if slabFlag == true import excel will not come
              <Button
                variant="text"
                onClick={() => {
                  setOpenQuotationExcel(true);
                  props.setquotationImported(false);
                  dispatch(ImportQuotation({}));
                }}
                sx={{
                  textDecoration: "underline",
                  textTransform: "capitalize",
                  p: 0,
                  "&:hover": {
                    backgroundColor: "transparent", // Set background to transparent
                  },
                }}
              >
                Import
                {props.data.vendors[0].transaction_thread.length > 1
                  ? " Requote"
                  : " Quotation"}{" "}
                Via Excel
              </Button>
            }
            </>
          }
        />

        <Divider sx={{ marginBottom: "0" }} />
        <CardContent>
          <Grid
            container
            spacing={2}
            className="local_recepients_card_content"
            id="quote_details_card"
          >
            {conditionFields.map((field) => {
              const renderedField = renderField(`${condition}`, field);
              return (
                renderedField && (
                  <Grid item key={field.id} xl={3} lg={4} md={4} sm={6} xs={12}>
                    {renderedField}
                  </Grid>
                )
              );
            })}
            {/* {conditionFields.map((field) => (
              <Grid item key={field.id} xl={4} lg={4} md={4} sm={6} xs={12}>
                {renderField(`${condition}`, field)}
              </Grid>
            ))} */}
            {(vendorType === "International" ||
              vendorType === "Local Importer") &&
              locData?.map((cell, i) => {
                const field = props?.getField(cell);
                if (
                  field &&
                  cell.VisibilityFlag === "Y" &&
                  cell.Identifier === "Currency"
                ) {
                  return (
                    <Grid item xl={3} lg={4} md={4} sm={6} xs={12} key={i}>
                      <FormControl
                        fullWidth
                        error={props.selectedCurrencyValidator}
                      >
                        {/* <InputLabel id="currency-label">Currency *</InputLabel> */}
                        <InputLabel id="currency-label">
                          {cell.FieldLabel +
                            (cell.InstructionText
                              ? ` (${cell.InstructionText})`
                              : "")}{" "}
                          *
                        </InputLabel>
                        <Select
                          labelId="currency-label"
                          onChange={props.handleCurrencyChange}
                          value={props?.selectedCurrency}
                          // label="Currency"
                          label={
                            cell.FieldLabel +
                            (cell.InstructionText
                              ? ` (${cell.InstructionText})`
                              : "") +
                            " *"
                          }
                          defaultValue={
                            props.lastTransactionTypeInThread === "Inquiry"
                              ? ""
                              : props.currencyDetails
                          }
                        >
                          <MenuItem value="">Select Currency</MenuItem>
                          {currencyOptions.map((currency) => (
                            <MenuItem
                              key={currency?.CurrencyID}
                              value={currency?.Currency}
                            >
                              {currency?.Currency}
                            </MenuItem>
                          ))}
                        </Select>
                        {props.selectedCurrencyValidator && (
                          <FormHelperText>
                            {props.selectedCurrencyErrorMessage}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  );
                }
              })}

            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="industry-type-label">
                  {featurePermission?.industry_type_mandatory?.feature_value ===
                  "Y"
                    ? "Industry Type *"
                    : "Industry Type"}
                </InputLabel>
                <Select
                  labelId="industry-type-label"
                  id="industry-type-select"
                  value={props?.industryType}
                  label={
                    featurePermission?.industry_type_mandatory
                      ?.feature_value === "Y"
                      ? "Industry Type *"
                      : "Industry Type"
                  }
                  required={
                    featurePermission?.industry_type_mandatory
                      ?.feature_value === "Y"
                      ? true
                      : false
                  }
                  onChange={(e) => props.onIndustryTypeChange(e.target.value)}
                  error={props.industryTypeValidator && !props.industryType}
                >
                  <MenuItem value="">Select Industry Type</MenuItem>
                  {industryOptions.map((option) => (
                    <MenuItem key={option.type} value={option.type}>
                      {option.type}
                    </MenuItem>
                  ))}
                </Select>
                {props.industryTypeValidator && !props.industryType && (
                  <FormHelperText sx={{ color: red[500] }}>
                    {props.industryTypeErrorMessage}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
              {props.industryType === "MSME" && (
                <TextField
                  label="MSME Number"
                  value={props?.msmeNumber}
                  variant="outlined"
                  fullWidth
                  onChange={(e) => props.onMsmeNumberChange(e.target.value)}
                  error={
                    props.msmeValidator &&
                    props.industryType === "MSME" &&
                    !props?.msmeNumber
                  }
                />
              )}
              {props.msmeValidator &&
                props.industryType === "MSME" &&
                !props?.msmeNumber && (
                  <FormHelperText sx={{ color: red[500] }}>
                    {"Please provide MSME number"}
                  </FormHelperText>
                )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {fileUploadOpen && (
        <UploadOfflineAttachment
          sessionID={props.newSessionID}
          currentFieldId={props.currentFieldId}
          uploadedFiles={newAttachmentValue}
          handleUplaodedFileDelete={handleUploadedFileDelete}
          setAttachmentsValue={(value) => {
            setAttachmentsValue(value);
            // props.setAttachmentsValue(value,props.currentTemplate,props.currentFieldId);
          }}
          randomId={props.randomId}
          open={fileUploadOpen}
          handleClose={() => setFileUploadOpen(false)}
        />
      )}
      <Grid container>
        <Grid item lg={12}>
          <CustomsmallDialog
            scroll="body"
            maxWidth={"sm"}
            open={OpenQuotationExcel}
            aria-labelledby="responsive-dialog-title"
          >
            {errContent || SuccessFlag ? (
              <>
                {" "}
                <DialogTitle
                  className="dialogcard_header_main"
                  sx={{
                    "&:focus-visible": {
                      border: "none",
                      outline: "none",
                    },
                  }}
                  tabIndex={-1}
                >
                  {SuccessFlag ? "Success" : "Errors"}
                </DialogTitle>
                <DialogContent>
                  {/* <DialogContentText>
            You can set my maximum width and whether to adapt or not.
          </DialogContentText> */}
                  {/* <Typography sx={{ fontSize: "14px", fontWeight: 500, mt: 2 }}>
               {errContent}
              </Typography> */}
                  {SuccessFlag ? (
                    "Your Detail Successfully filled."
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: errContent }} />
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="outlined"
                    color="primary"
                    autoFocus
                    onClick={handleCloseExcel}
                  >
                    Close
                  </Button>
                </DialogActions>
              </>
            ) : (
              <>
                <DialogTitle
                  className="dialogcard_header_main"
                  sx={{
                    "&:focus-visible": {
                      border: "none",
                      outline: "none",
                    },
                  }}
                  tabIndex={-1}
                >
                  Import
                  {props.data.vendors[0].transaction_thread.length > 1
                    ? " Requote"
                    : " Quotation"}{" "}
                  Via Excel
                </DialogTitle>
                <DialogContent>
                  <div className="dropzone">
                    <CustomDropzone
                      accept=".xls, .xlsx"
                      // maxFiles: 1,
                      maxFiles={1} // Set maxFiles to 1 to allow only one file
                      onDrop={(acceptedFiles) => {
                        let validFilesList = [];

                        validFilesList = handleFilesChange(acceptedFiles);
                        if (validFilesList.length > 0) {
                          setValidFiles(validFilesList);
                        }
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="dz-message document_upload_main">
                            <div className="drop_zone_area">
                              <Typography variant="p">
                                <CloudUploadIcon sx={{ mr: 1 }} /> Drag and Drop
                                or <span>Browse</span> to upload
                              </Typography>
                            </div>
                          </div>
                        </div>
                      )}
                    </CustomDropzone>
                  </div>
                  {/* <DialogContentText>
            You can set my maximum width and whether to adapt or not.
          </DialogContentText> */}
                  <Typography sx={{ fontSize: "14px", fontWeight: 500, mt: 2 }}>
                    Download Sample Format:{" "}
                    <Link
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        downloadSampleFile(1);
                      }}
                    >
                      {DownloadLoader[1] ? (
                        <>
                          <CircularProgress color="inherit" size="0.8rem" />
                          <span style={{ marginLeft: "8px" }}>
                            Downloading...
                          </span>
                        </>
                      ) : (
                        "Vertical"
                      )}
                    </Link>{" "}
                    |{" "}
                    <Link
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        downloadSampleFile(2);
                      }}
                    >
                      {" "}
                      {DownloadLoader[2] ? (
                        <>
                          <CircularProgress color="inherit" size="0.8rem" />
                          <span
                            style={{
                              marginLeft: "8px",
                              textDecoration: "none",
                            }}
                          >
                            Downloading...
                          </span>
                        </>
                      ) : (
                        "Horizontal"
                      )}
                    </Link>
                  </Typography>
                  <Box className="FileUploadList" mt={1} mb={2}>
                    <List sx={{ p: "0" }}>{files}</List>
                  </Box>
                  <Typography sx={{ fontSize: "14px", fontWeight: 500, mt: 2 }}>
                    File size should be between 1KB to 25MB
                  </Typography>
                  <Typography sx={{ fontSize: "12px", color: "grey.500" }}>
                    Note: The file should be of one of the following file
                    types(*.xls,*.xlsx,*.csv)
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    disabled={Loader || validFiles.length === 0}
                    onClick={importQuotation}
                    startIcon={
                      Loader && <CircularProgress color="inherit" size="1rem" />
                    }
                    color="primary"
                  >
                    Import
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    autoFocus
                    onClick={handleCloseExcel}
                  >
                    Close
                  </Button>
                </DialogActions>{" "}
              </>
            )}
          </CustomsmallDialog>
        </Grid>
      </Grid>
    </>
  );
};

export default QuotationDetailsSection;
